//--------------------------------------------------------------
// CLEARFIX
//--------------------------------------------------------------

@mixin clearfix {
	&::before,
	&::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}
