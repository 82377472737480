//--------------------------------------------------------------
// WYSIWYG CONTENT
//--------------------------------------------------------------

//-----------------------------------------
// Default
//-----------------------------------------
.wysiwyg-content {
	// Hide empty WordPress injected <p> tags.
	*:empty:not(hr):not(br):not(input):not(select):not(textarea):not(img):not(iframe) {
		display: none;
	}

	// Remove top margin from first element.
	*:first-child {
		margin-top: 0;

		& > img {
			margin-top: 0;
		}
	}

	// Remove bottom margin from last element.
	*:last-child {
		margin-bottom: 0;

		& > img {
			margin-bottom: 0;
		}
	}

	// Responsive Media
	.responsive-embed {
		margin: 24px auto;
		max-width: none;
		width: auto;
	}
}

//-----------------------------------------
// Paragraph
//-----------------------------------------
.wysiwyg-content {
	p {
		margin-bottom: 12px;

		@include mq($mq-full) {
			margin-bottom: 24px;
		}
	}
}

//-----------------------------------------
// List
//-----------------------------------------
.wysiwyg-content {
	ul,
	ol,
	dl {
		margin-bottom: 24px;

		@include mq($mq-full) {
			margin-bottom: 32px;
		}
	}

	// Unordered List
	ul {
		list-style-type: disc;
		padding-left: 1em;
		padding-right: 2em;

		@include mq($mq-full) {
			padding-right: 3em;
		}
	}

	// Ordered List
	ol {
		counter-reset: item;
		list-style-type: decimal;
		padding-left: 0;
		padding-right: 2em;

		@include mq($mq-full) {
			padding-right: 3em;
		}
	}

	// Definition List
	dl {
		padding-left: 0;
		padding-right: 2em;

		@include mq($mq-full) {
			padding-right: 3em;
		}
	}
}

//-----------------------------------------
// List Item
//-----------------------------------------
.wysiwyg-content {
	li {
		&:not(:last-child) {
			margin-bottom: 18px;
		}
	}

	// Unordered List Item
	ul {
		li {
		}
	}

	// Ordered List Item
	ol {
		li {
			counter-increment: item;
			list-style-type: none;
			padding-left: 1em;
			position: relative;

			&::before {
				content: counter(item) '.';
				font-weight: $font-weight-bold;
				left: 0;
				position: absolute;
				top: 0;
			}
		}
	}

	// Definition List
	dl {
		// Definition Term
		dt {
		}

		// Definition Name
		dd {
			margin-bottom: 20px;
		}
	}
}

//-----------------------------------------
// Blockquote
//-----------------------------------------
.wysiwyg-content {
	blockquote {
		border-left: 2px solid $color-black;
		margin: 30px 0;
		padding: 15px;

		& > p {
			color: $color-gray;
			line-height: 1.5;
		}
	}
}

//-----------------------------------------
// Cite
//-----------------------------------------
.wysiwyg-content {
	cite {
		color: $color-primary;
		display: block;

		&::before {
			content: '\2014 \0020';
		}
	}
}

//-----------------------------------------
// Horizontal Rule
//-----------------------------------------
.wysiwyg-content {
	hr {
		margin: 24px auto;
	}
}

//-----------------------------------------
// Table
//-----------------------------------------
.wysiwyg-content {
	table {
	}
}

//-----------------------------------------
// Form
//-----------------------------------------
.wysiwyg-content {
	form {
	}
}

//-----------------------------------------
// Anchor
//-----------------------------------------
.wysiwyg-content {
	a {
		position: relative;

		&:not(.button) {
			color: $color-primary;
			text-decoration: underline;
		}

		&:hover,
		&:focus {
			&:not(.button) {
				color: $color-secondary;
			}
		}
	}
}

//-----------------------------------------
// Image
//-----------------------------------------
.wysiwyg-content {
	img {
		display: block;
		height: auto;
		line-height: 0;
		width: auto;
	}
}
