//--------------------------------------------------------------
// GLOBAL PAGE HEADER
//--------------------------------------------------------------

.g-page-header {
	background-color: lighten($color-buttercup, 15%);

	&__background {
		min-height: 400px;
	}

	&__title-container {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: inherit;
	}

	&__custom-title {
		background-color: lighten($color-buttercup, 15%);
		color: $color-white;
		display: inline-block;
		padding: 10px 15px;
		text-align: center;
	}
}
