//--------------------------------------------------------------
// FORM
//--------------------------------------------------------------

.form {
	&__checkbox,
	&__radio {
		label,
		input {
			display: inline-block;
		}
	}

	&__group {
	}

	&__section {
		margin-bottom: 20px;
	}

	&__input {
	}

	&__textarea {
	}
}

//-----------------------------------------
// Contact
//-----------------------------------------
.form {
	&--contact {
	}
}
