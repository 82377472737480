//--------------------------------------------------------------
// GLOBAL FOOTER
//--------------------------------------------------------------

.g-footer {
	background-color: $color-theme-black;
	color: $color-theme-white;
	padding: 40px 0;
	width: 100%;

	&__row {
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr;

		@include mq($mq-laptop) {
			grid-template-columns: 1fr 1fr;
		}
	}

	&__title {
		font-size: rem-calc(24);
		font-weight: $font-weight-bold;
		margin-bottom: 15px;
		text-transform: uppercase;
	}

	&__copyright {
		font-size: rem-calc(14);
	}
}
