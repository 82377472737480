//--------------------------------------------------------------
// LAZYLOAD
//--------------------------------------------------------------

.lazyload,
.lazyloading {
	opacity: 0;
	transition: opacity 0.3s ease-out;
}

.lazyloaded {
	opacity: 1;
	transition: opacity 0.3s ease-out;
}
