//--------------------------------------------------------------
// FORM SEARCH
//--------------------------------------------------------------

.form-search {
	display: flex;
	margin: 20px 0;
	width: 260px;

	&__input {
		margin: 0;
		width: 100%;
	}

	&__button {
		background-color: $color-black;
		border: 0;
		color: $color-white;
		cursor: pointer;
		padding: 0 20px;

		&:hover {
			background-color: $color-white;
			color: $color-black;
		}
	}
}
