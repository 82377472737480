//--------------------------------------------------------------
// BUTTONS
//--------------------------------------------------------------

.button {
	-webkit-appearance: none;
	background-color: $color-primary;
	border: 0;
	border-radius: 0;
	color: $color-white;
	cursor: pointer;
	display: inline-block;
	font-size: $font-size-content;
	line-height: 1;
	margin: 0;
	padding: 10px 15px;
	position: relative;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.25s ease-out, color 0.25s ease-out, border-color 0.25s ease-out;
	width: auto;

	&:hover,
	&:focus {
		background-color: $color-secondary;
		color: $color-white;
	}

	&--secondary {
		background-color: $color-secondary;
		color: $color-white;

		&:hover,
		&:focus {
			background-color: $color-primary;
			color: $color-white;
		}
	}

	&--full-width {
		display: block;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	&--hollow {
		background-color: transparent;
		border: 2px solid $color-secondary;
		color: $color-secondary;

		&:hover,
		&:focus {
			background-color: $color-secondary;
			color: $color-white;
		}
	}

	&--hollow-white {
		background-color: transparent;
		border: 2px solid $color-white;
		color: $color-white;

		&:hover,
		&:focus {
			background-color: $color-secondary;
			border-color: $color-secondary;
			color: $color-white;
		}
	}

	&--reset {
		background-color: transparent;
		border: 0;
	}

	&[disabled] {
		background-color: transparent;
		border: 1px solid $color-theme-gray;
		color: $color-theme-gray;
		cursor: default;
	}
}
