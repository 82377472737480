//--------------------------------------------------------------
// MAIN HERO
//--------------------------------------------------------------

.main-hero {
	align-items: center;
	background-image: url('../images/shape-main-hero.jpg');
	background-position: center top;
	display: grid;
	grid-template-rows: 1fr 1fr;
	min-height: 533px;
	padding: 0 10px;
	text-align: center;
	width: 100%;

	&__group {
		padding-top: 30px;
	}

	&__h1 {
		color: $color-secondary;
		text-transform: uppercase;
	}

	&__intro {
		color: $color-white;
		font-weight: $font-weight-bold;
		line-height: 1.1;
		margin: 0 auto 20px;
		max-width: 600px;
	}
}
