//--------------------------------------------------------------
// MAIN PANEL
//--------------------------------------------------------------

.main-panel {
	align-items: center;
	display: grid;
	grid-template-columns: 1fr;

	@include mq($mq-laptop) {
		grid-template-columns: 1fr 1fr;
	}

	&__image {
		align-self: start;
		display: block;
		width: 100%;
	}

	// <span>
	&__cursive {
		display: block;
		font-family: $font-family-cursive;
	}

	&__content {
		padding: 20px;

		@include mq($mq-laptop) {
			padding: 50px;
		}
	}

	&__text-main {
		margin: 0 auto 30px;
		max-width: 500px;
	}

	&__text-small {
		font-size: rem-calc(14);
	}
}
