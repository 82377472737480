//--------------------------------------------------------------
// ACCESSIBILITY
//--------------------------------------------------------------

// Hide visually but allows for screen readers to access it.
// @see https://make.wordpress.org/accessibility/2015/02/09/hiding-text-for-screen-readers-with-wordpress-core/

@mixin visual-hide {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal;
}

// Reverse hide.
@mixin reverse-visual-hide {
	clip: auto;
	clip-path: none;
	height: auto;
	margin: 0;
	overflow: visible;
	padding: 0;
	position: static;
	width: auto;
}
