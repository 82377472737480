//--------------------------------------------------------------
// HELPERS
//--------------------------------------------------------------

.h-visual-hide {
	@include visual-hide;
}

.h-reverse-visual-hide {
	@include reverse-visual-hide;
}

.h-hidden {
	display: none;
}

.h-clearfix {
	@include clearfix;
}

//-----------------------------------------
// Cover
//-----------------------------------------
.h-cover-absolute {
	bottom: 0;
	height: 100%;
	left: 0;
	min-height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}

.h-cover-background {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.h-cover-video {
	height: auto;
	max-height: none;
	max-width: none;
	min-height: 100%;
	min-width: 100%;
	width: auto;
}

//-----------------------------------------
// Text
//-----------------------------------------
.h-text-center {
	text-align: center;
}

//-----------------------------------------
// Colors
//-----------------------------------------
.h-color-primary {
	color: $color-primary;
}

.h-color-secondary {
	color: $color-secondary;
}

.h-color-tertiary {
	color: $color-tertiary;
}
