//--------------------------------------------------------------
// MAIN PARALLAX
//--------------------------------------------------------------

.main-parallax {
	background-attachment: fixed;
	background-image: url('../images/main-parallax.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 780px;

	&__content {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: inherit;
	}

	&__heading {
		color: $color-white;
		font-size: rem-calc(80);
		font-weight: $font-weight-black;
		line-height: 1.2;
		text-align: center;
		text-transform: uppercase;
	}

	&__heading-small {
		display: block;
		font-size: rem-calc(30);
	}

	&--fun {
		background-image: url('../images/parallax-fun.jpg');
	}
}
