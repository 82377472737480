//--------------------------------------------------------------
// POSTS
//--------------------------------------------------------------

//-----------------------------------------
// Date
//-----------------------------------------
.date {
	color: $color-theme-gray;
}

//-----------------------------------------
// Categories
//-----------------------------------------
.categories {
}

//-----------------------------------------
// Tags
//-----------------------------------------
.tags {
	span {
	}
}

.post-title {
	font-family: $font-family-cursive;
}

.type-post {
	margin-bottom: 40px;
}

//-----------------------------------------
// Page
//-----------------------------------------
.page-content {
	margin-top: 30px;
}
