//--------------------------------------------------------------
// SETTINGS
//--------------------------------------------------------------

//-----------------------------------------
// Global
//-----------------------------------------
$global-width: 1180px;
$global-padding: 20px;
$global-radius: 5px;

//-----------------------------------------
// Media Queries
//-----------------------------------------
$mq-mobile-medium: 375px;
$mq-mobile-large: 425px;
$mq-medium: 640px;
$mq-tablet: 768px;
$mq-wordpress: 783px;
$mq-full: 960px;
$mq-laptop: 1024px;
$mq-global-width: $global-width;
$mq-laptop-large: 1440px;

//-----------------------------------------
// Fonts
//-----------------------------------------
$font-roboto: 'Roboto', sans-serif;
$font-dancing-script: 'Dancing Script', cursive;

// Family
$font-family-body: $font-roboto;
$font-family-heading: $font-roboto;
$font-family-cursive: $font-dancing-script;

// Size
$font-size-content: 21px;

// Weight
$font-weight-light: 300;
$font-weight-normal: 400; // Roboto, Dancing Script
$font-weight-bold: 700; // Roboto
$font-weight-black: 900; // Roboto

//-----------------------------------------
// Colors
//-----------------------------------------
// Neutrals
$color-black: #000000;
$color-white: #FFFFFF;
$color-gray: #808080;

// Theme Colors
$color-fuel-yellow: #F2A22A;
$color-old-brick: #96251E;
$color-buttercup: #F5A109;
$color-alabaster: #FAFAFA;
$color-mine-shaft: #242323;

// Roles
$color-primary: $color-fuel-yellow;
$color-secondary: $color-old-brick;
$color-tertiary: $color-buttercup;
$color-theme-black: $color-mine-shaft;
$color-theme-white: $color-alabaster;
$color-theme-gray: $color-gray;

// Alerts
$color-error: #EA595E;
$color-warning: #F0BD2B;
$color-success: #59EA9D;
