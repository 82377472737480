//--------------------------------------------------------------
// GLOBAL HEADER
//--------------------------------------------------------------

.g-header {
	position: relative;
	width: 100%;
	z-index: 2;

	&__container {
		align-items: center;
		display: grid;
		gap: 20px;
		grid-template-columns: 210px 1fr;
		padding: 20px;

		@include mq($mq-laptop) {
			grid-template-columns: 350px 1fr;
		}
	}

	&__logo {
		display: block;
	}

	&__navigation {
		@include mq($mq-tablet, false) {
			justify-self: end;
		}
	}
}

//-----------------------------------------
// Top
//-----------------------------------------
.g-header {
	&__top {
		background-color: $color-theme-black;
		padding: 10px 0;
	}

	&__top-row {
		align-items: center;
		display: flex;
		justify-content: flex-end;
	}

	&__top-logo {
		margin-left: 20px;
	}
}

//-----------------------------------------
// Bottom
//-----------------------------------------
.g-header {
	&__bottom {
		background-color: $color-primary;
	}
}
