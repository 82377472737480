//--------------------------------------------------------------
// GLOBAL LAYOUT ROW
//--------------------------------------------------------------

.g-l-row {
	display: flow-root;
	margin: 0 auto;
	max-width: $global-width;
	padding: 0 $global-padding;
	width: 100%;

	// We need this until 'flow-root' is fully supported.
	&--clearfix {
		@include clearfix;
	}

	&--medium {
		max-width: 800px;
	}

	&--small {
		max-width: 400px;
	}
}
