//--------------------------------------------------------------
// COMPONENT SOCIAL MEDIA
//--------------------------------------------------------------

.c-social-media {
	display: inline-flex;

	// <li>
	&__item {
		&:not(:last-child) {
			margin-right: 20px;
		}
	}

	// <a>
	&__link {
		color: $color-white;
		font-size: rem-calc(30);

		&:hover {
			color: $color-primary;
		}
	}

	svg {
		display: block;
	}
}
