//--------------------------------------------------------------
// BLOCK CONTENT
//--------------------------------------------------------------

.block-content {
	color: $color-primary;
	display: grid;
	gap: 35px;
	margin: 0 auto 50px;
	max-width: 1240px; // 1090 + 150
	padding: 0 25px;

	@include mq($mq-tablet) {
		margin-bottom: 100px;
	}
	@include mq($mq-laptop) {
		gap: 65px;
		padding: 0 75px;
	}

	// Modifiers
	&--center {
		grid-template-columns: minmax(auto, 600px);
		justify-content: center;
	}

	&--left {
		@include mq($mq-tablet) {
			grid-template-columns: minmax(auto, 425px) minmax(auto, 600px);
		}
	}

	&--right {
		@include mq($mq-tablet) {
			grid-template-columns: minmax(auto, 600px) minmax(auto, 425px);
		}
	}

	// Image
	&__image {
		&--left {
			order: 1;

			@include mq($mq-tablet) {
				order: 0;
			}
		}

		&--right {
			order: 1;
		}
	}

	// Content
	&__content {
		max-width: 600px;

		&--left {
			@include mq($mq-tablet) {
				padding-right: 50px;
			}
		}

		&--right {
			@include mq($mq-tablet) {
				padding-left: 50px;
			}
		}
	}
}
